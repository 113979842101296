<template>
  <!-- 创新赛验证页面 -->
  <div>
    <Footer></Footer>
    <div class="content">
      <!-- 背景底图 -->
      <img src="./innovation_img/verify.jpg" alt="">
      <!-- 登录窗口 -->
      <div class="box">
        <!-- 标题 -->
        <p class="title">身份校验</p>
        <el-input style="margin-top: .6rem;" class="inp" v-model="name" placeholder="队长姓名："></el-input>
        <el-input class="inp" v-model="numID" placeholder="队长身份证号："></el-input>
        <el-button class="but" @click="verify" type="success" round>身 份 验 证</el-button>
        <p class="details" @click="details">点击查看详情>>></p>
      </div>
    </div>

    <!-- 版权所有 -->
    <div class="copyright">
      <div class="copyright_hurdle">
        <ul>
          <li @click="navigation('大赛首页')">大赛首页</li>
          <li>|</li>
          <li @click="navigation('赛事介绍')">赛事介绍</li>
          <li>|</li>
          <li @click="navigation('官方文件')">官方文件</li>
          <li>|</li>
          <li @click="navigation('赛区规划')">赛区规划</li>
          <li>|</li>
          <li @click="navigation('大赛培训')">大赛培训</li>
          <li>|</li>
          <li @click="navigation('联系我们')">联系我们</li>
        </ul>
      </div>
      <p class="copyright_p">Copyright 2022,中信科移动</p>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import { getLogin } from "api/apis.js";
import $ from "jquery";

export default {
  name: 'Home',
  components: {
    Footer,
    Bottom
  },
  data() {
    return {
      name: "",
      numID: "",
    }
  },
  methods: {
    verify() {
      if (this.name != "" && this.numID != "") {
        let dataobj = new FormData();
        dataobj.append("name", this.name); //姓名
        dataobj.append("id_num", this.numID); //身份证号
        getLogin.verify(dataobj).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            // const obj = JSON.parse(res.data)
            // this.$store.commit('UPverify', obj)
            sessionStorage.setItem('member_id', res.member_id);
            sessionStorage.setItem('group', res.group);
            setTimeout(() => {
              this.$router.push("/step");
            }, 2000);
          } else if (res.code == 0) {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message.error('请您填写完整信息！');
      }

    },
    details() {
      this.$router.push("/innovation");
    },
    // 底部导航
    navigation(val) {
      if (val == '大赛首页') {
        this.$router.push("/HomePage");
      } else if (val == '赛事介绍') {
        this.$router.push("/Brief");
      } else if (val == '官方文件') {
        this.$router.push("/File");
      } else if (val == '赛区规划') {
        this.$router.push("/fingerpost");
      } else if (val == '大赛培训') {
        this.$router.push("/Train");
      } else if (val == '联系我们') {
        this.$router.push("/Contact");
      }
    },
  },
}
</script>
  
<style scoped lang="scss">
@import './verify.scss'
</style>